import styled from 'react-emotion'
import t from 'src/theme'

export const NextEventContainer = styled.div`
  margin-bottom: ${t.s(5)};

  &:last-child {
    margin-bottom: 0;
  }

  ${t.mq.m} {
    align-content: center;
    display: flex;
    flex: 1 1 0px;
    margin-bottom: 0;
    margin-right: ${t.s(5)};
    max-width: 50%;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const NextEventPanel = styled.div`
  align-content: center;
  background-color: ${t.c.transparentBlack};
  border-radius: 3px;
  border: solid 1px ${t.c.pink};
  color: ${t.c.white};
  display: flex;
  flex-direction: column;
  font-family: ${t.ff.monoBody};
  padding: ${t.s(2)} 0;
  text-align: center;
  width: 100%;

  h2 {
    font-weight: 800;
  }
  ${t.mq.m} {
    padding: ${t.s(3)} ${t.s(2)};
  }
`

export const Description = styled.p`
  margin: ${t.s(1)};
  line-height: ${t.lh.wide};
`
