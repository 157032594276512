import React from 'react'
import { get, replace } from 'lodash'

import {
  NextEventPanel,
  NextEventContainer,
  Description,
} from './NextEvent.styled'

export const NextEvent = ({ event }) => (
  <NextEventContainer>
    <NextEventPanel>
      <h2>Upcoming Event</h2>
      <div>{get(event, 'data.event_name')}</div>
      <div>
        {replace(get(event, 'data.event_date').substring(5), '-', '/')}
        {` ${String.fromCharCode(183)} `}
        {' ' + get(event, 'data.event_time')}
        {` ${String.fromCharCode(183)} `}
        {' ' + get(event, 'data.location.document.data.location_name')}
      </div>
      <Description>{get(event, 'data.event_description.text')}</Description>
    </NextEventPanel>
  </NextEventContainer>
)
