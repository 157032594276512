import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'

import { nodes } from 'src/helpers'
import { Layout } from 'src/components/Layout/Layout'
import { Carousel } from 'src/components/Shared/Carousel/Carousel'
import { Intro } from 'src/components/Index/Intro/Intro'
import { Locations } from 'src/components/Index/Locations/Locations'
import { Events } from 'src/components/Index/Events/Events'
import { OffsetImages } from 'src/components/Shared/OffsetImages/OffsetImages'
import { MenuLinks } from 'src/components/Index/MenuLinks/MenuLinks'
import { FeaturedProducts } from 'src/components/Index/FeaturedProducts/FeaturedProducts'
import { Image } from 'src/components/Shared/Image'
import { HeroTitle, Hero } from './_index.styled'

const IndexPage = ({ data }) => {
  const page = { ...data.prismicIndex.data }
  const locations = nodes(data.allPrismicLocation)
  const events = nodes(data.allPrismicEvent)

  return (
    <Layout>
      <Hero>
        <Carousel>
          {page.carousel.map(item => {
            return (
              get(item, 'carousel_image.fluid') && (
                <Image
                  fluid={get(item, 'carousel_image.fluid')}
                  alt={item.alt_text}
                  key={item.alt_text}
                />
              )
            )
          })}
        </Carousel>
        <HeroTitle>
          <p>Hawaii's</p>
          <p>Neighborhood</p>
          <p>Cafe</p>
        </HeroTitle>
      </Hero>
      <Intro
        title={get(page, 'intro_text[0].intro_title')}
        body={get(page, 'intro_text[0].intro_body.text')}
        button={get(page, 'intro_text[0].intro_button')}
        url={get(page, 'intro_text[0].intro_button_url.url')}
      />
      <Locations locations={locations} />
      <Events events={events} />
      <OffsetImages
        imageOneUrl={get(page, 'restaurant_image_one.url')}
        imageTwoUrl={get(page, 'restaurant_image_two.url')}
        message="You're always with friends <br /> at Kalapawai."
      />
      <MenuLinks locations={locations} />
      <FeaturedProducts />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    prismicIndex {
      id
      first_publication_date
      last_publication_date
      data {
        hero_text {
          text
        }
        intro_text {
          intro_title
          intro_body {
            text
          }
          intro_button
          intro_button_url {
            url
          }
        }
        carousel {
          carousel_image {
            fluid(maxWidth: 1400) {
              ...GatsbyPrismicImageFluid
            }
          }
          alt_text
        }
        restaurant_image_one {
          alt
          url
        }
        restaurant_image_two {
          alt
          url
        }
      }
    }
    allPrismicLocation {
      edges {
        node {
          id
          uid
          data {
            location_name
            location_card_image {
              fluid(maxWidth: 1400) {
                ...GatsbyPrismicImageFluid
              }
            }
            google_maps_link {
              url
            }
          }
        }
      }
    }
    allPrismicEvent {
      edges {
        node {
          id
          data {
            event_name
            event_date
            event_time
            location {
              document {
                ... on PrismicLocation {
                  uid
                  data {
                    location_name
                  }
                }
              }
            }
            event_description {
              text
            }
          }
        }
      }
    }
  }
`
