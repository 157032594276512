import React from 'react'
import { get, replace } from 'lodash'
import { Table } from 'reactstrap'

import { EventTableContainer, Heading } from './EventTable.styled'

export const EventTable = ({ events }) => (
  <EventTableContainer>
    <Heading color="transparentWhite">Upcoming Events</Heading>
    <Table>
      <tbody>
        {events.map(event => (
          <tr key={get(event, 'data.event_date')}>
            <th scope="row">{get(event, 'data.event_name')}</th>
            <td>
              {replace(get(event, 'data.event_date').substring(5), '-', '/')}
            </td>
            <td>{get(event, 'data.event_time')}</td>
            <td>{get(event, 'data.location.document.data.location_name')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </EventTableContainer>
)
