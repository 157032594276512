import React from 'react'
import { get, head, tail, isEmpty } from 'lodash'

import { ExtraLargeContentContainer } from 'src/components/Layout/Structure'
import { EventsContainer, FlexContainer } from './Events.styled'
import { NextEvent } from './NextEvent'
import { EventTable } from './EventTable'

export const Events = ({ children, ...props }) => {
  const allEvents = get(props, 'events').sort((a, b) => {
    return (
      new Date(get(b, 'data.event_date')) - new Date(get(a, 'data.event_date'))
    )
  })

  if (isEmpty(allEvents)) return null

  return (
    <EventsContainer id="events">
      <ExtraLargeContentContainer>
        <FlexContainer>
          <NextEvent event={head(allEvents)} />
          {!isEmpty(tail(allEvents)) && <EventTable events={tail(allEvents)} />}
        </FlexContainer>
      </ExtraLargeContentContainer>
    </EventsContainer>
  )
}
