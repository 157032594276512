import React from 'react'

import { LargeContentContainer } from 'src/components/Layout/Structure'
import { IntroContainer, StyledButton } from './Intro.styled'

export const Intro = ({ children, title, body, button, url, ...props }) => (
  <IntroContainer>
    <LargeContentContainer>
      <h2>{title}</h2>
      <p>{body}</p>
      <StyledButton to={url}>{button}</StyledButton>
    </LargeContentContainer>
  </IntroContainer>
)
