import React from 'react'
import { get } from 'lodash'

import { ExtraLargeContentContainer } from 'src/components/Layout/Structure'
import { Location } from 'src/components/Index/Location/Location'
import { LocationsContainer, LocationsRow } from './Locations.styled'

export const Locations = ({ children, locations }) => {
  locations.sort((a, b) => a.uid.localeCompare(b.uid))
  return (
    <LocationsContainer>
      <ExtraLargeContentContainer>
        <h2>Locations</h2>
        <p>For menu & hours, please choose a neighborhood:</p>
        <LocationsRow>
          {locations.map(location => {
            return (
              <Location
                key={get(location, 'data.location_name')}
                location={location}
                locationName={get(location, 'data.location_name')}
                image={get(location, 'data.location_card_image.fluid')}
              />
            )
          })}
        </LocationsRow>
      </ExtraLargeContentContainer>
    </LocationsContainer>
  )
}
