import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'

export const MenuLinksContainer = styled.div`
  color: ${t.c.black};
  padding: 0 ${t.s(2)};
  padding-bottom: ${t.s(5)};
  padding-top: ${t.s(5)};
  background-color: ${t.c.white};
  background: linear-gradient(180deg, ${t.c.lightCream} 50%, ${t.c.white} 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const MenuPanel = styled.div`
  background-color: ${t.c.white};
  display: flex;
  border: solid 2px ${t.c.gray};
  border-radius: 3px;
  font-family: ${t.ff.monoBody};
  flex-direction: column;
  ${t.mq.s} {
    flex-direction: row;
  }
`

export const MenuIntro = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: ${t.s(3)};
  border-bottom: solid 2px ${t.c.gray};
  text-align: center;

  h2 {
    color: ${t.c.lightGreen};
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  p {
    color: ${t.c.gray};
    line-height: ${t.lh.copy};
  }

  ${t.mq.s} {
    width: 50%;
    border: 0;
    border-right: solid 2px ${t.c.gray};
  }
`

export const MenuLinkList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${t.mq.s} {
    width: 50%;
  }
`

export const MenuLink = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.1em;
  color: ${t.c.gray};
  padding: ${t.s(1)};
  flex-grow: 1;
  border-bottom: solid 2px ${t.c.gray};
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${t.c.gray};
    color: ${t.c.white};
    cursor: pointer;
    font-weight: 600;
  }
`

export const Border = styled.div`
  border: solid 2px ${t.c.gray};
  padding: calc(1rem / 3);
  background-color: ${t.c.white};
  border-radius: 3px;
`
