import React from 'react'
import { Container } from 'reactstrap'
import { get } from 'lodash'

import { LargeContentContainer } from 'src/components/Layout/Structure'
import {
  MenuLinksContainer,
  MenuPanel,
  MenuIntro,
  MenuLinkList,
  MenuLink,
  Border,
} from './MenuLinks.styled'

export const MenuLinks = ({ locations }) => (
  <MenuLinksContainer>
    <LargeContentContainer>
      <Container>
        <Border>
          <MenuPanel>
            <MenuIntro>
              <h2>Menus</h2>
              <p>
                Serving what you crave since 1932. Our food is designed to fuel
                every local lifestyle.
              </p>
            </MenuIntro>
            <MenuLinkList>
              {locations.map(location => (
                <MenuLink key={location.uid} to={'/' + location.uid + '/#menu'}>
                  {get(location, 'data.location_name')}
                </MenuLink>
              ))}
            </MenuLinkList>
          </MenuPanel>
        </Border>
      </Container>
    </LargeContentContainer>
  </MenuLinksContainer>
)
