import styled from 'react-emotion'

import t from 'src/theme'
import { Button } from 'src/components/Shared/Button/Button'
import { Image } from 'src/components/Shared/Image'
import { Product } from './Product'

export const FeaturedProductsContainer = styled.div`
  background-color: ${t.c.white};
  color: ${t.c.black};
  display: flex;
  justify-content: center;
  padding: ${t.s(2, 2, 6)};
  position: relative;
  text-align: center;
  z-index: 1;

  h2 {
    color: ${t.c.green};
    text-align: center;
  }
  overflow: hidden;
`

export const Flowers = styled.img`
  position: absolute;
  left: -20%;
  bottom: 20%;
  width: ${t.s(8.5)};
  z-index: -1;
  pointer-events: none;

  ${t.mq.s} {
    width: ${t.s(9.25)};
  }

  ${t.mq.m} {
    bottom: auto;
    left: -5%;
    top: -20%;
    width: ${t.s(9.5)};
  }

  ${t.mq.xx} {
    width: ${t.s(10)};
  }
`

export const Products = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${t.s(2)};
  width: 100%;

  ${t.mq.m} {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const StyledProduct = styled(Product)`
  display: none;
  margin-bottom: ${t.s(3)};
  width: 100%;
  max-width: ${t.s(8)};

  &:first-child {
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${t.mq.m} {
    flex: 1 1 0px;
    margin-bottom: 0;
    margin-left: ${t.s(1)};
    max-width: 100%;
    padding: ${t.s(2)} 0;

    &:nth-child(2) {
      display: block;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  ${t.mq.l} {
    display: block;
  }
`

export const StyledProductGiftCard = styled(StyledProduct)`
  display: block;
  img {
    max-width: 252px;
  }

  ${t.mq.m} {
    margin-left: ${t.s(2)};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export const ProductGiftCardContent = styled.div`
  align-items: center;
  background-color: ${t.c.lightGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: ${t.s(2)};
  text-align: center;

  img {
    margin-bottom: ${t.s(1)};

    ${t.mq.m} {
      flex: 1 1 100%;
    }
  }

  p {
    margin: 0;
  }
`

export const StyledButton = styled(Button)`
  display: inline-block;
`

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
`

export const ComingSoonMessage = styled.div`
  color: ${t.c.gray};
  font-family: ${t.ff.monoBody};
`
