import styled from 'react-emotion'
import t from 'src/theme'

export const LocationsContainer = styled.div`
  display: none;
  justify-content: center;
  background-color: ${t.c.white};
  padding: ${t.s(5)} ${t.s(2)};
  text-align: center;
  margin-top: -${t.s(1)};
  margin-left: -${t.s(1)};

  h2 {
    color: ${t.c.green};
    font-family: ${t.ff.sansHeading};
  }
  p {
    color: ${t.c.darkGray};
    margin-bottom: ${t.s(2)};
    font-family: ${t.ff.monoBody};
  }
  ${t.mq.m} {
    display: flex;
    flex-direction: column;
  }
`

export const LocationsRow = styled.div`
  display: flex;
  justify-content: space-between;
`
