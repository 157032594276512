import React from 'react'
import { StyledLink, Container, Content, Name } from './Product.styled'

export const Product = ({ children, name, to, ...props }) => (
  <Container {...props}>
    <StyledLink to={to}>
      <Content>{children}</Content>
      {name && <Name>{name}</Name>}
    </StyledLink>
  </Container>
)
