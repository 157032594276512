import styled from 'react-emotion'

import t from 'src/theme'
import { TapeHeading } from 'src/components/Shared/TapeHeading/TapeHeading'

export const EventTableContainer = styled.div`
  color: ${t.c.white};
  margin: 0;
  width: 100%;

  ${t.mq.m} {
    flex: 1 1 0px;
    margin: 0;
  }

  table {
    margin: 0;
  }
  td,
  th {
    border-top: solid 1px ${t.c.lightCream};
    border-bottom: none;
    font-family: ${t.ff.monoBody};
    font-size: ${t.f(-1)};
    font-weight: 800;
    padding-right: 0;
  }

  ${t.mq.s} {
    td,
    th {
      font-weight: 500;
      font-size: ${t.f(0)};
    }
  }
`

export const Heading = styled(TapeHeading)`
  color: ${t.c.white};
  font-size: ${t.f(0.5)};
  font-weight: 900;
  margin-bottom: ${t.s(0)};
`
