import styled from 'react-emotion'
import { AspectRatio } from 'src/components/Shared/AspectRatio/AspectRatio'

export const ImageAspectRatio = styled(AspectRatio)`
  width: 100%;
  position: relative;
`

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  hight: 100%;
`
