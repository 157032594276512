import styled from 'react-emotion'

import t from 'src/theme'
import AssetTextureChalk from 'src/assets/texture-chalk.png'

export const EventsContainer = styled.div`
  align-content: center;
  background-color: #222;
  background-image: url(${AssetTextureChalk});
  background-repeat: repeat;
  background-size: 100% auto;
  color: ${t.c.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${t.s(5)} ${t.s(2)};
`

export const FlexContainer = styled.div`
  width: 100%;
  padding: 0 ${t.s(1)};

  ${t.mq.m} {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  ${t.mq.l} {
    padding: 0;
  }
`
