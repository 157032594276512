import styled from 'react-emotion'

import t from 'src/theme'
import { AspectRatio } from 'src/components/Shared/AspectRatio/AspectRatio'
import AssetTexturePalaka from 'src/assets/palaka.png'

export const ImageAspectRatio = styled(AspectRatio)`
  width: 100%;
  position: relative;
`

export const Image = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  object-fit: cover;
  display: block;
  width: calc(100% - ${t.s(3)});
  height: calc(100% - ${t.s(3)});
`

export const PalakaBackground = styled.div`
  background-image: url(${AssetTexturePalaka});

  width: calc(100% - ${t.s(3)});
  height: calc(100% - ${t.s(3)});
`
