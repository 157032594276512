import React from 'react'
import { get } from 'lodash'

import { Link } from 'src/components/Shared/Link/Link'
import {
  LocationContainer,
  StyledLink,
  ImageContainer,
  StyledImage,
  StyledAspectRatio,
} from './Location.styled'

export const Location = ({ children, ...props }) => (
  <LocationContainer {...props}>
    <StyledLink to={'/' + props.location.uid}>
      <StyledAspectRatio x={1} y={1}>
        <ImageContainer>
          <StyledImage fluid={props.image} />
        </ImageContainer>
      </StyledAspectRatio>
    </StyledLink>
    <h2>{props.locationName}</h2>
    <div>
      <Link to={'/' + props.location.uid + '#menu'}>Menu</Link>
      {` ${String.fromCharCode(183)} `}
      <Link
        to={get(props, 'location.data.google_maps_link.url')}
        target="_blank"
      >
        Directions
      </Link>
    </div>
  </LocationContainer>
)
