import styled from 'react-emotion'

import t from 'src/theme'
import { AspectRatio } from 'src/components/Shared/AspectRatio/AspectRatio'
import AssetTexturePalaka from 'src/assets/palaka.png'
import AssetTextureSigns from 'src/assets/palmTrees.svg'
import { PalakaImage } from './PalakaImage'
import { SecondaryImage } from './SecondaryImage'

export const RestaurantImagesContainer = styled.div`
  display: none;
  color: ${t.c.black};
  background-color: ${t.c.lightCream};
  padding: ${t.s(5)} ${t.s(2)};
  background-image: url(${AssetTextureSigns});
  background-repeat: no-repeat;
  background-position: 105% 70%;
  background-size: auto 75%;
  ${t.mq.m} {
    display: block;
  }
`

export const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

export const WithFriends = styled.div`
  width: 60%;
  position: absolute;
  bottom: 0.5%;
  right: 5%;
  font-family: 'Quickbrush';
  text-align: center;
  font-size: ${t.f(6)};
`

export const StyledPalakaImage = styled(PalakaImage)`
  width: 100%;
  display: inline-block;
  margin-bottom: ${t.s(2)};

  ${t.mq.m} {
    width: calc(50% - (${t.s(6)} / 2));
    margin-right: ${t.s(6)};
    margin-top: ${t.s(7)};
    margin-bottom: 0;
  }
`

export const StyledSecondaryImage = styled(SecondaryImage)`
  width: 100%;
  display: inline-block;
  ${t.mq.m} {
    width: calc(50% - (${t.s(6)} / 2));
  }
`

export const ImageAspectRatio = styled(AspectRatio)`
  width: 100%;
  position: relative;
`

export const PalakaBackground = styled.div`
  background-image: url(${AssetTexturePalaka});

  width: calc(100% - ${t.s(3)});
  height: calc(100% - ${t.s(3)});
`

export const ImageRow = styled.div``
