import styled from 'react-emotion'
import t from 'src/theme'

export const HeroTitle = styled.div`
  margin-top: 0;
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: ${t.ff.script};
  color: ${t.c.white};
  font-size: ${t.f(3)};
  font-weight: 200;
  pointer-events: none;

  p:nth-child(2) {
    margin-top: -${t.s(3)};
    margin-left: ${t.s(3)};
    ${t.mq.s} {
      margin-top: -${t.s(3)};
      margin-left: ${t.s(5)};
    }
    ${t.mq.l} {
      margin-top: -${t.s(3)};
      margin-left: ${t.s(6)};
    }
  }

  p:nth-child(3) {
    margin-top: -${t.s(3)};
    margin-left: ${t.s(6)};
    ${t.mq.s} {
      margin-top: -${t.s(3)};
      margin-left: ${t.s(8)};
    }
    ${t.mq.l} {
      margin-top: -${t.s(3)};
      margin-left: ${t.s(9)};
    }
  }

  ${t.mq.t} {
    margin-top: ${t.s(0)};
    font-size: ${t.f(5)};
  }

  ${t.mq.s} {
    margin-top: ${t.s(2)};
    font-size: ${t.f(7)};
  }
  ${t.mq.m} {
    margin-top: ${t.s(1)};
    font-size: ${t.f(8)};
  }
  ${t.mq.l} {
    margin-top: ${t.s(0)};
    margin-left: ${t.s(3)};
    font-size: ${t.f(9)};
  }
  ${t.mq.x} {
    font-size: ${t.f(9)};
    margin-top: ${t.s(2)};
    margin-left: ${t.s(5)};
  }
  ${t.mq.xx} {
    font-size: ${t.f(10)};
    margin-top: ${t.s(4)};
    margin-left: ${t.s(5)};
  }
`
export const Hero = styled.div`
  position: relative;
`
// height: ${t.s(7)};
// overflow: hidden;

// ${t.mq.s} {
//   height: ${t.s(9)};
// }
// ${t.mq.l} {
//   height: ${t.s(10)};
// }
// ${t.mq.xx} {
//   height: ${t.s(11)};
// }
