import styled from 'react-emotion'
import { Link } from 'src/components/Shared/Link/Link'
import t from 'src/theme'

export const Container = styled.div`
  display: block;
  font-family: ${t.ff.monoBody};
  a {
    text-decoration: none;
  }
`
export const StyledLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`

export const Content = styled.div`
  ${t.mq.s} {
    height: ${t.s(8)};
  }
`

export const Name = styled.p`
  color: ${t.c.lightGreen};
  text-transform: uppercase;
  font-weight: 900;
  margin: ${t.s(1)} 0 0;
  text-align: center;
  font-size: ${t.f(1)};
  letter-spacing: 0.05em;
`

export const Image = styled.img`
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
`
