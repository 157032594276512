import React from 'react'
import { get, map } from 'lodash'

import { ExtraLargeContentContainer } from 'src/components/Layout/Structure'
import AssetMediaGiftCard from 'src/assets/media-gift-card.png'
import AssetTextureFlowers from 'src/assets/texture-flowers.png'
import {
  FeaturedProductsContainer,
  Flowers,
  Products,
  StyledProduct,
  StyledProductGiftCard,
  ProductGiftCardContent,
  StyledButton,
  ProductImage,
} from './FeaturedProducts.styled'
import { graphql, useStaticQuery } from 'gatsby'

export const FeaturedProducts = ({ children, ...props }) => {
  const queryData = useStaticQuery(graphql`
    query FeaturedProductsQuery {
      shopifyCollection {
        products {
          handle
          images
          title
          id
        }
      }
    }
  `)

  return (
    <FeaturedProductsContainer>
      <Flowers src={AssetTextureFlowers} />
      <ExtraLargeContentContainer>
        <h2>Shop</h2>

        <Products>
          {map(get(queryData, 'shopifyCollection.products'), product => (
            <StyledProduct
              key={get(product, 'id')}
              name={get(product, 'title')}
              to={`https://kalapawai-market.myshopify.com/products/${get(
                product,
                'handle'
              )}`}
            >
              {get(product, 'images[0]') && (
                <ProductImage
                  src={get(product, 'images[0]')}
                  alt={get(product, 'title')}
                />
              )}
            </StyledProduct>
          ))}

          <StyledProductGiftCard to="/gift-cards">
            <ProductGiftCardContent>
              <img src={AssetMediaGiftCard} alt="Gift card" />
              <p>Gift Cards</p>
            </ProductGiftCardContent>
          </StyledProductGiftCard>
        </Products>

        <StyledButton to="https://shop.kalapawaimarket.com/">
          See All
        </StyledButton>
      </ExtraLargeContentContainer>
    </FeaturedProductsContainer>
  )
}
