import styled from 'react-emotion'
import { AspectRatio } from '@walltowall/siamese'

import t from 'src/theme'
import { Image } from 'src/components/Shared/Image'
import { Link } from 'src/components/Shared/Link/Link'

export const LocationContainer = styled.div`
  color: ${t.c.gray};
  background-color: ${t.c.white};
  display: flex;
  width: 32%;
  flex-direction: column;
  align-items: center;
  margin-top: ${t.s(1)};
  margin-left: ${t.s(1)};

  h2 {
    color: ${t.c.lightGreen};
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-family: ${t.ff.sansHeading};
    margin-bottom: ${t.s(-1)};
  }

  a {
    font-family: ${t.ff.monoBody};
    color: ${t.c.gray};
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const StyledLink = styled(Link)`
  width: 100%;
  margin-bottom: ${t.s(1.5)};
  overflow: hidden;
`

export const ImageContainer = styled.div`
  // height: 400px;
  width: 100%;
  // margin-bottom: ${t.s(1.5)};  
  // overflow: hidden;
`

export const StyledImage = styled(Image)`
  height: 100%;
  transform: scale(1);
  transition: transform calc(${t.t} * 2);

  ${StyledLink}:hover & {
    transform: scale(1.1);
    cursor: pointer;
  }
`

export const StyledAspectRatio = styled(AspectRatio)`
  overflow: hidden;
`
