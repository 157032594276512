import React from 'react'

import { Image, PalakaBackground, ImageAspectRatio } from './PalakaImage.styled'

export const PalakaImage = ({ children, ...props }) => (
  <ImageAspectRatio x={1} y={1} {...props}>
    <PalakaBackground />
    <Image src={props.src} />
  </ImageAspectRatio>
)
