import styled from 'react-emotion'

import t from 'src/theme'
import AssetTextureSigns from 'src/assets/signs.svg'
import { Link } from 'src/components/Shared/Link/Link'

export const IntroContainer = styled.div`
  align-items: center;
  background-color: ${t.c.pink};
  background-image: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: ${t.lh.wide};
  padding: ${t.s(6)} ${t.s(1)};
  text-align: center;

  ${t.mq.s} {
    background-image: url(${AssetTextureSigns});
    background-repeat: no-repeat;
    background-position: 15% 50%;
    background-size: auto 105%;
  }

  h2 {
    color: ${t.c.green};
    font-family: ${t.ff.sansHeadline};
    font-size: ${t.f(4)};
    margin-bottom: ${t.s(3)};
  }

  p {
    color: ${t.c.darkGray};
    margin: 0 auto;
    margin-bottom: ${t.s(3)};
    font-family: ${t.ff.monoBody};
  }
`

export const StyledButton = styled(Link)`
  display: inline-block;
  font-family: ${t.ff.monoBody};
  a {
    text-decoration: none;
  }
  &:hover {
    cursor: pointer;
    background-color: ${t.c.green};
    a {
      text-decoration: none;
    }
  }
`
