import React from 'react'

import { LargeContentContainer } from 'src/components/Layout/Structure'
import {
  RestaurantImagesContainer,
  StyledPalakaImage,
  StyledSecondaryImage,
  WithFriends,
  FlexContainer,
} from './OffsetImages.styled'

export const OffsetImages = ({
  children,
  imageOneUrl,
  imageTwoUrl,
  message,
  ...props
}) => (
  <RestaurantImagesContainer {...props}>
    <LargeContentContainer>
      <FlexContainer>
        <StyledPalakaImage src={imageOneUrl} />
        <StyledSecondaryImage src={imageTwoUrl} />
        <WithFriends dangerouslySetInnerHTML={{ __html: message }} />
      </FlexContainer>
    </LargeContentContainer>
  </RestaurantImagesContainer>
)
